$lightGray: #cecece;
$grayDark: #a2a5a4;
$gray2: #183956;
$gray5: #f0f5f8;
$gray6: #667d99;
$gray7: #050615;
$red: #b10000;
$lightRed: #db4835;
$orange: #c5782b;
$lightOrange: #f6e6d5;
$hoveredLink: #1c5a84;
$white: #ffffff;
$black: #21282a;
$black64Opacity: rgba(33, 40, 42, 0.64);
$black1: #212121;
$darkBlueGrey: #333f4d;
$grey: #7a7d80;
$lightBlueGrey: #acb9c8;
$link: #2979ff;
$green: #268e36;
$green2: #296932;
$green2Opacity15: rgba(41, 105, 50, 0.15);
$lightBrightGreen: #90ee90;
$lightGreen: #9ccba3;
$pink: #e91e63;
$yellow: #f2ef1d;
$whatsappGreen: #128c7e;

$blackTransparent: rgba(0, 0, 0, 0.870588);
$transparent: rgba(0, 0, 0, 0);

$error: $red;

$lightPrimaryMain: $hoveredLink;

$xxSmallScreenWidth: 375px;
$xSmallScreenWidth: 479px;
$smallScreenWidth: 768px;
$mediumScreenWidth: 968px;
$xMediumScreenWidth: 1024px;
$newLargeScreenWidth: 1366px;
$largeScreenWidth: 1440px;

$breakpoints: (
  xxSmallScreenBkp: $xxSmallScreenWidth,
  xSmallScreenBkp: $xSmallScreenWidth,
  smallScreenBkp: $smallScreenWidth,
  mediumScreenBkp: $mediumScreenWidth,
  xMediumScreenBkp: $xMediumScreenWidth,
  newLargeScreenBkp: $newLargeScreenWidth,
  largeScreenBkp: $largeScreenWidth
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

@mixin mq-dark-mode {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@import './animate-pulse.css';
@import 'react-alice-carousel/lib/scss/alice-carousel.scss';

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-x: hidden;
}

hr {
  margin-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid #e7ebf0;
}

.footer-link {
  color: #00c8ff;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

#page-wrap {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 75px;

  @media print {
    padding: 0 !important;
    display: block;
  }
}

#inner-container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;

  @media (min-width: 768px) {
    max-width: 704px;
    padding: 10px;
    padding-top: 24px;
  }

  @media (min-width: 968px) {
    max-width: 960px;
    padding-top: 24px;
  }

  @media (min-width: 1300px) {
    max-width: 1210px;
    padding-top: 24px;
  }

  @media print {
    max-width: 100%;
    padding: 40px;
  }
}

.av-block-ui {
  overflow: hidden;
}

@media print {
  @page {
    margin: 10mm 0 25mm 0;
    size: portrait;
  }
  html,
  body {
    height: auto !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    width: 100%;
    margin: auto;
  }
}
