$lightGray: #cecece;
$grayDark: #a2a5a4;
$gray2: #183956;
$gray5: #f0f5f8;
$gray6: #667d99;
$gray7: #050615;
$red: #b10000;
$lightRed: #db4835;
$orange: #c5782b;
$lightOrange: #f6e6d5;
$hoveredLink: #1c5a84;
$white: #ffffff;
$black: #21282a;
$black64Opacity: rgba(33, 40, 42, 0.64);
$black1: #212121;
$darkBlueGrey: #333f4d;
$grey: #7a7d80;
$lightBlueGrey: #acb9c8;
$link: #2979ff;
$green: #268e36;
$green2: #296932;
$green2Opacity15: rgba(41, 105, 50, 0.15);
$lightBrightGreen: #90ee90;
$lightGreen: #9ccba3;
$pink: #e91e63;
$yellow: #f2ef1d;
$whatsappGreen: #128c7e;

$blackTransparent: rgba(0, 0, 0, 0.870588);
$transparent: rgba(0, 0, 0, 0);

$error: $red;

$lightPrimaryMain: $hoveredLink;
